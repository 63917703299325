<template>
	<div :class="{hidden: !active}">
		<slot></slot>
	</div>
</template>

<script>
import { EventEmitter } from 'InterfaceBundle'

export default {
	name: 'BlTab',
	props: {
		name: {},
		disabled: {
			type: Boolean,
			default: false
		},
		lazyload: {
			type: Boolean,
			default: false
		}
	},
	inject: ['blTabsRegister', 'blTabsUnregister', 'blTabUpdate'],
	data() {
		return {
			active: false,
			index: null,
			hasBeenActivated: false,
			blTabLoadBody: new EventEmitter()
		}
	},
	mounted() {
		this.blTabsRegister(this)
		this.setBodyLoad()
	},
	beforeUnmount() {
		this.blTabsUnregister(this)
	},
	provide() {
		return {
			blTabLoadBody: this.blTabLoadBody
		}
	},
	methods: {
		setBodyLoad() {
			this.blTabLoadBody.emit(!this.lazyload || this.hasBeenActivated)
		}
	},
	watch: {
		lazyload() {
			this.setBodyLoad()
		},
		hasBeenActivated() {
			this.setBodyLoad()
		},
		disabled() {
			this.blTabUpdate()
		}
	}
}
</script>

<style scoped lang="scss">
	.hidden {
		overflow: hidden;
		width: 0;
		height: 0;
		position: relative;
	}
</style>