<template>
	<div class="bl-tabs-container">
		<ul class="bl-tabs" ref="headerContainer" v-if="tabs.length">
			<div class="bl-tabs-slider" ref="slider" :class="{animate: animateSlider}" :style="{display: showSlider ? null : 'none'}"></div>
			<li v-for="tab in tabs" :key="tab.index" :index="tab.index" v-html="tab.header" @click="selectTab(tab)" :class="{active: tab.active, disabled: tab.scope.disabled}"></li>
		</ul>
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'BlTabs',
	props: ['modelValue'],
	emits: ['update:modelValue'],
	data() {
		return {
			tabs: [],
			showSlider: false,
			hasActiveTab: false,
			animateSlider: false,
			elementPreviousWidth: 0,
			resizeObserver: new ResizeObserver(() => {
				if(this.elementPreviousWidth != this.$el.offsetWidth) {
					this.elementPreviousWidth = this.$el.offsetWidth
					this.animateSlider = false
					this.$nextTick(() => {
						this.setSliderPosition()
						setTimeout(() => this.animateSlider = true, 200)
					})
				}
			}),
			focusChangeEvent: () => this.focusChange()
		}
	},
	watch: {
		modelValue() {
			let matching = this.tabs.filter(t => t.scope.name === this.modelValue || t.index === this.modelValue)
			if(matching.length) this.selectTab(matching[0])
		}
	},
	methods: {
		registerTab(tab) {
			let index = this.tabs.reduce((i, t) => i > t.index ? i : t.index, 0) + 1
			this.tabs.push({
				scope: tab,
				header: tab.$el.querySelector(':scope > .blTabHeaderContainer').innerHTML,
				active: false,
				index: index
			})
			tab.index = index
			if(!this.hasActiveTab) {
				this.hasActiveTab = true
				this.$nextTick(() => this.autoSelect())
			}
			this.$nextTick(() => this.setSliderPosition())
		},
		unregisterTab(tab) {
			let reselect = tab.active
			this.tabs = this.tabs.filter(t => t.index != tab.index)
			this.$nextTick(() => reselect ? this.autoSelect() : this.setSliderPosition())
		},
		unselectTab(tab) {
			tab.active = false
			tab.scope.active = false
		},
		selectTab(tab) {
			if(!tab || tab.scope.disabled) return
			for(let t of this.tabs) this.unselectTab(t)
			tab.active = true
			tab.scope.hasBeenActivated = true
			tab.scope.active = true
			this.$emit('update:modelValue', tab.scope.name ? tab.scope.name : tab.index)
			this.setSliderPosition()
			this.$nextTick(() => {
				window.dispatchEvent(new Event('resize'))//Trigger resize for hidden tabs elements (eg. Ace editor)
				this.$refs.headerContainer.querySelector('li.active').scrollIntoView({behavior: 'smooth', inline: 'center', block: 'nearest'})
			})
			setTimeout(() => this.animateSlider = true, 200)
		},
		setSliderPosition() {
			this.showSlider = false
			let tab = this.tabs.filter(t => t.active)
			if(!tab.length) return
			this.showSlider = true
			tab = tab[0]
			let headerElement = this.$refs.headerContainer.querySelector(':scope > li[index="' + tab.index + '"]')
			this.$refs.slider.style.width = headerElement.offsetWidth + 'px'
			this.$refs.slider.style.marginLeft = headerElement.offsetLeft + 'px'
		},
		focusChange() {
			for(let tab of this.tabs) {
				if(!tab.active && tab.scope.$el.contains(document.activeElement)) {
					this.selectTab(tab)
					break
				}
			}
		},
		autoSelect() {
			let setSelection = false
			let noActiveTabs = true
			for(let tab of this.tabs) {
				if(tab.active && tab.scope.disabled) {
					this.unselectTab(tab)
					setSelection = true
					break
				}
				if(tab.active) noActiveTabs = false
			}
			if(setSelection || noActiveTabs) {
				let setSlider = true
				for(let tab of this.tabs) {
					if(!tab.scope.disabled) {
						this.selectTab(tab)
						setSlider = false
						break
					}
				}
				if(setSlider) this.setSliderPosition()
			}
		}
	},
	provide() {
		return {
			blTabsRegister: tab => this.registerTab(tab),
			blTabsUnregister: tab => this.unregisterTab(tab),
			blTabUpdate: () => this.autoSelect()
		}
	},
	mounted() {
		document.addEventListener('focusin', this.focusChangeEvent)
		this.resizeObserver.observe(this.$el)
		setTimeout(() => this.setSliderPosition(), 50)
	},
	unmounted() {
		document.removeEventListener('focusin', this.focusChangeEvent)
		this.resizeObserver.unobserve(this.$el)
	}
}
</script>

<style scoped lang="scss">
	div.bl-tabs-slider {
		background-color: var(--bl-primary);
		height: 3px;
		left: 0;
		position: absolute;
		bottom: 0;
		border-top-right-radius: var(--bl-border-radius);
		border-top-left-radius: var(--bl-border-radius);
	}

	div.bl-tabs-slider.animate {
		transition: all .2s ease-in-out;
	}
</style>