<template>
	<div class="blTabHeaderContainer">
		<slot></slot>
		<div v-if="notification !== false" class="blTabHeaderContainerNotification">{{ notification === true ? '' : notification }}</div>
	</div>
</template>

<script>
export default {
	name: 'BlTabHeader',
	props: {
		notification: {
			type: [Number, Boolean],
			default: false
		}
	}
}
</script>

<style scoped lang="scss">
	div {
		display: none;
	}
</style>