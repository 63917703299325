export default {
	mounted(el, binding) {
		el.addEventListener('mouseenter', () => {
			el.tooltipComponent = document.createElement('DIV')
			el.tooltipComponent.innerText = binding.value
			el.tooltipComponent.classList.add('bl-tooltip-container')
			document.body.appendChild(el.tooltipComponent)

			setTimeout(() => {
				if(el) {
					let top = el.getBoundingClientRect().y + el.getBoundingClientRect().height + 3
					if((top + el.getBoundingClientRect().height) > window.innerHeight)
						top = el.getBoundingClientRect().y - el.tooltipComponent.getBoundingClientRect().height - 3
					if(el.tooltipComponent) {
						el.tooltipComponent.style.top = top + 'px'
						el.tooltipComponent.style.left = (el.getBoundingClientRect().x + (el.getBoundingClientRect().width / 2) - (el.tooltipComponent.getBoundingClientRect().width / 2)) + 'px'
					}
				}
			})
		})
		el.addEventListener('mouseleave', () => {
			if(el.tooltipComponent) {
				el.tooltipComponent.parentNode.removeChild(el.tooltipComponent)
				el.tooltipComponent = null
			}
		})
		el.addEventListener('click', () => {
			if(el.tooltipComponent && el.tooltipComponent.parentNode) {
				el.tooltipComponent.parentNode.removeChild(el.tooltipComponent)
				el.tooltipComponent = null
			}
		})
	},
	unmounted(el) {
		if(el.tooltipComponent) el.tooltipComponent.parentNode.removeChild(el.tooltipComponent)
	}
}